import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import '../css/contact.css';

const ContactForm = () => {
  const { t } = useTranslation();
  
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    country: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    message: ''
  });

  // Initialize emailjs once when the component is mounted
  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Use emailjs.sendForm instead of manually constructing template params
    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID_CONTACT,
      e.target,  // the form element is passed directly
      process.env.REACT_APP_EMAILJS_USER_ID
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert(t('Form submitted successfully!'));
      // Clear form fields after successful submission
      setFormData({
        name: '',
        surname: '',
        country: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        message: ''
      });
    })
    .catch((error) => {
      console.error('FAILED...', error);
      alert(t('Failed to send the form. Please try again later.'));
    });
  };

  return (
    <section className="container contact-form-section">
      <form className="container contact-form" id="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            name="name"
            placeholder={t('Emër *')}
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="surname"
            placeholder={t('Mbiemër *')}
            value={formData.surname}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="country"
            placeholder={t('Shteti *')}
            value={formData.country}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder={t('Email *')}
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="phoneNumber"
            placeholder={t('Numër Telefoni *')}
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="companyName"
            placeholder={t('Emri i Kompanisë (Opsional)')}
            value={formData.companyName}
            onChange={handleChange}
          />
        </div>
        <textarea
          name="message"
          placeholder={t('Mesazh *')}
          value={formData.message}
          onChange={handleChange}
          required
        />
        <button type="submit" className="btn btn-primary btn-submit-form">{t('Dërgo')}</button>
      </form>
    </section>
  );
};

export default ContactForm;
