import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import './../css/footer.css'; // Ensure this path is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="container footer-img">
        <div className="footer-bottom">
          <div className="footer-column footer-social">
            <h4>{t('Kontakt')}</h4>
            <div className="socials-container">
              <div className="social-row">
                <FontAwesomeIcon icon={faLocationDot} className="location-icon" />
                <a href="https://maps.app.goo.gl/KSj9tWi9wQQRgJzc9" className="footer-link">
                  {t('Rruga Bilal Golemi 33, Tirana, Albania')}
                </a>
              </div>
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/email.png`} alt="Email" />
                <a href="mailto:contact@melioramedical.al" className="footer-link">
                  {t('contact@melioramedical.al')}
                </a>
              </div>
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/phone-call.png`} alt="Phone Number" />
                <a href="tel:+355676869685" className="footer-link">
                  {t('+355 67 686 9685')}
                </a>
              </div>
            </div>
          </div>

          <div className="footer-logo">
            <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="Meliora Medical Logo" className="footer-logo-img" />
          </div>

          <div className="footer-column footer-socials">
            <h4>{t('Rrjetet Sociale')}</h4>
            <div className="socials-container">
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/tiktok.png`} alt="TikTok" />
                <a href="https://www.tiktok.com/@meliora__medical" className="footer-link">
                  {t('TikTok')}
                </a>
              </div>
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/instagram.png`} alt="Instagram" />
                <a href="https://www.instagram.com/meliora_medical/?locale=nl&hl=af" className="footer-link">
                  {t('Instagram')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copy">
          <p className="footer-copy">
            {t('Copyright &copy; Meliora Medical. Të gjitha të drejtat e rezervuara')}
          </p>
          <p className="footer-copy-synaps" dangerouslySetInnerHTML={{ __html: t('Dizenjuar nga Synaps') }}></p>
        </div>
      </div>
    </Fragment>
  );
}

export default Footer;
