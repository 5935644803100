import React from 'react';
import '../css/about.css';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Helmet>
                <title>{t('about.title')} - Meliora Medical</title>
            </Helmet>
            <header className="header-about" id="intro">
                <div className="container header__container">
                    <div className="header__left">
                        <h1>{t('about.title')}</h1>
                    </div>
                </div>
                <img
                    srcSet={`${process.env.PUBLIC_URL}/assets/About Us Hero-2000w.png 2000w,
                             ${process.env.PUBLIC_URL}/assets/About Us Hero-1000w.png 1000w,
                             ${process.env.PUBLIC_URL}/assets/About Us Hero-500w.png 500w`}
                    sizes="(min-width: 1000px) 2000px, (min-width: 600px) 1000px, 500px"
                    src={`${process.env.PUBLIC_URL}/assets/About Us Hero-500w.png`}
                    alt={t('about.heroAlt')}
                    style={{ display: 'none' }}
                />
            </header>

            {/* Section 1 - Values */}
            <section className="container about-section about-intro">
                <h1>{t('about.clinicTitle')}</h1>
                <p>{t('about.clinicDescription')}</p>
            </section>

            {/* Section 2 - Our Mission */}
            <section className="container about-section about-mission">
                <div className="about-text">
                    <h2>{t('about.missionTitle')}</h2>
                    <p>{t('about.missionDescription')}</p>
                </div>
                <div className="about-image">
                    <img src={`${process.env.PUBLIC_URL}/assets/mission.png`} alt={t('about.missionImageAlt')} />
                </div>
            </section>

            {/* Section 3 - Our Vision */}
            <section className="container about-section about-vision">
                <div className="about-text">
                    <h2>{t('about.visionTitle')}</h2>
                    <p>{t('about.visionDescription')}</p>
                </div>
                <div className="about-image">
                    <img src={`${process.env.PUBLIC_URL}/assets/vision.png`} alt={t('about.visionImageAlt')} />
                </div>
            </section>
        </div>
    );
};

export default About;
