import './App.css';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LoadingScreen from './components/LoadingScreen';
import LanguageSwitcher from './components/LanguageSwitcher';

import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';

const App = () => {
  const location = useLocation();

  const getBackgroundClass = (pathname) => {
    switch (pathname) {
      case '/':
        return 'homepage-background';
      case '/about':
        return 'about-background';
      case '/services':
        return 'services-background';
      case '/contact':
        return 'contact-background';
   
    }
  };

  return (
    <div className={`App ${getBackgroundClass(location.pathname)}`}>
      <LoadingScreen />
      <Navbar />
      <div className="content-wrapper">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      <LanguageSwitcher />
      <Footer />
    </div>
  );
};

export default App;
