import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './../css/languageswitcher.css'; // The CSS you provided
import './../i18n/i18n.js'; // Import the i18n configuration

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isChecked, setIsChecked] = useState(true); // Default to Albanian ('al')

  useEffect(() => {
    // Set the initial state based on the current language
    setIsChecked(i18n.language === 'en'); // Check if current language is English ('en')
  }, [i18n.language]);

  const toggleLanguage = () => {
    const newLanguage = isChecked ? 'al' : 'en'; // Toggle the language based on isChecked
    i18n.changeLanguage(newLanguage);
    setIsChecked(!isChecked); // Invert the isChecked state
  };

  return (
    <div className="language-switcher-container">
      <label className="language-switcher">
        <input type="checkbox" checked={isChecked} onChange={toggleLanguage} />
        <span className="slider round"></span>
        <span className="select-en">AL</span>
        <span className="select-al">EN</span>
      </label>
    </div>
  );
};

export default LanguageSwitcher;
